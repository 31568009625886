import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const SecureResilientSolutionsProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Secure and optimize your{' '}
        <span className="heading-red">AWS environment with Simform</span>
      </>
    ),
    bannerContent:
      'We design fault-tolerant systems, configure firewalls, and implement automatic failover mechanisms to minimize downtime, prevent breaches, and ensure compliance.',
    BannerAlt: `Secure and Resilient Solutions`,
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Simform for{' '}
        <span className="highlight-head">AWS Security and Resilience</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            System failures, cyber threats, and misconfigurations disrupt
            business operations, causing downtime, breaches, and compliance
            issues.
            <br /> <br />
            As an AWS Premier Partner, Simform builds robust and resilient
            systems and deploys web application firewalls to shield your
            applications from threats, malicious traffic, and DDoS attacks. Our
            AWS-certified experts ensure your infrastructure is secure and
            compliant across multiple accounts.
          </>
        ),
      },
      {
        detailsList: [
          'Fault-tolerant system architectures',
          'Automated disaster recovery',
          'Web application firewall protection',
          'Secure multi-account provisioning',
          'Governance and security best practices',
          'Resilience assessments and optimization',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: <>Our secure and resilient solutions</>,
    servicesPara:
      'Our AWS-certified cloud experts carefully analyze your current environment, vulnerabilities, and business continuity needs. With this understanding, we strategically design and implement customized solutions to boost resilience, strengthen security, and establish robust governance across your AWS ecosystem.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'AWS Resilience Services',
        className: 'custom-software',
        mainPara: (
          <>
            We construct highly durable cloud architectures that remain
            operational during failures and outages. Our architects use AWS
            Resilience services to automate recovery processes, reliably back up
            data, and facilitate chaos engineering.
          </>
        ),
        listItem: [
          {
            title: 'Resiliency assessment',
            para:
              'We evaluate critical workloads, procedures, and environments to provide a detailed resilience profile, recovery benchmarking, and optimization roadmap.',
          },
          {
            title: 'Architecture design',
            para:
              'Our experts design fault-tolerant architectures by using AWS services such as Auto Scaling, Elastic Load Balancing, and Multi-AZ deployments to minimize downtime.',
          },
          {
            title: 'Disaster recovery planning',
            para:
              'We help you set RPO and RTO policies, resilience tiers, automated runbooks, and tested procedures for failover, backup recovery, and instance redeployment.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-resilience/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS Web Application Firewall (WAF) Services',
        className: 'software-testing',
        mainPara: `Simform safeguards your web applications and APIs against common exploits and malicious traffic. We achieve this through comprehensive protection provided by AWS WAF Services, which includes DDoS shielding and continuous threat monitoring.`,
        listItem: [
          {
            title: 'Web traffic filtering',
            para:
              'We create custom rule configurations for specific traffic types based on predefined criteria to filter out malicious traffic to ensure a tailored and secure online experience.',
          },
          {
            title: 'API and SSL/TSL security',
            para:
              'Our AWS experts enable encryption, configure access controls, implement authentication mechanisms, and create security policies to safeguard your APIs against threats.',
          },
          {
            title: 'Security consultation',
            para:
              'We assess your security, identify vulnerabilities, and recommend tailored strategies. Our experts create, manage, and update web traffic filtering policies to align with your requirements.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-web-application-firewall/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS Control Tower Services',
        className: 'enterprise-mobile',
        mainPara:
          'Simform sets up secure, compliant multi-account AWS environments following best practices. Our experts provision accounts, implement guardrails, and ensure continuous governance with AWS Control Tower Services.',
        listItem: [
          {
            title: 'Centralized governance',
            para:
              'We create a unified compliance and governance framework for managing multiple accounts, improving your ability to oversee governance across diverse environments.',
          },
          {
            title: 'Enhanced data control',
            para:
              'Our AWS experts design your landing zones and guardrails to provide granular control over data storage and processing, ensuring secure deployments.',
          },
          {
            title: 'Automated deployments',
            para:
              'We design a modular architecture for your landing zones across AWS environments that automate deployments implementing CI/CD best practices.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-control-tower-services/',
        type: EXTERNAL_LINK,
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Why choose <span className="highlight-head"> Simform?</span>
      </>
    ),
    processPara: `Moving to the cloud is just the beginning. As an AWS Premier Consulting Partner, Simform goes beyond infrastructure building, ensuring your cloud environment consistently evolves alongside your business.`,
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'AWS certified and excellence',
        workingBenefitsContent: (
          <>
            With over 200 AWS-certified experts, Simform designs resilient,
            secure, and compliant cloud architectures tailored to your industry
            needs.
            <br />
            <br />
            By leveraging our deep understanding of AWS best practices, we
            future-proof your cloud strategy, ensuring efficiency and readiness
            for cloud.
          </>
        ),
        benefitImgAlt: 'AWS certified and excellence',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Minimum latency',
        workingBenefitsContent: (
          <>
            We help minimize the impact on your web application's performance by
            swiftly inspecting any part of web requests within seconds.
            <br />
            <br />
            This real-time analysis and responsiveness ensure that your
            applications remain highly performant, secure, and adaptable to
            dynamic traffic patterns.
          </>
        ),
        benefitImgAlt: 'Minimum latency',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Agile mindset and scalability',
        workingBenefitsContent: (
          <>
            Simform doesn't just build your cloud infrastructure, we make it
            future-proof.
            <br />
            <br />
            Our AWS experts ensure your AWS environment is agile and can scale
            seamlessly to meet your evolving needs.
          </>
        ),
        benefitImgAlt: 'Agile mindset and scalability',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'End to end support',
        workingBenefitsContent: (
          <>
            We offer comprehensive end-to-end support for all your AWS accounts,
            covering initial setup, configurations, monitoring, and ongoing
            maintenance.
            <br />
            <br />
            Our dedicated team ensures your systems are always up, running, and
            optimized, allowing you to focus on your core business operations.
          </>
        ),
        benefitImgAlt: 'End to end support',
      },
    ],
  },
  gauranteeSecProps: {
    heading: (
      <>
        Work with an official{' '}
        <span className="highlight-head">AWS Premier Consulting Partner</span>
      </>
    ),
    para:
      'Simform, with its cloud partners, brings you the superpower of cloud-native applications. Our strategic alliances empower organizations like yours to thrive in the cloud.',
    gauranteeAlt: 'Extended tech team',
  },
  awardsRecognitionProps: {
    heading: 'Learn more about our AWS partner programs',
    AwsCardData: [
      {
        cardLink: '/aws-well-architected-review/',
      },
      {
        cardLink: '/aws-immersion-days/',
      },
    ],
  },
  relatedcaselistProps: {
    heading: (
      <>
        <span className="highlight-head">Case Studies</span>
      </>
    ),
    caselistProps: [
      {
        title: 'A scalable parking and valet management system',
        para:
          'Parking and valet management system for multiple users and vendors.',
        className: 'pale-blue',
        link: '/tagb-waf/',
      },
      {
        title: 'Automotive Digital Marketing Platform',
        para:
          'Built a high performance, secure and scalable digital marketing platform catering specifically to automotive clients.',
        className: 'light-peach',
        link: '/carsaver-resillience/',
      },
    ],
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: 'About Simform’s AWS Practices',
    detailsList: [
      <>
        <strong>
          Simform is an official premier consulting partner of AWS
        </strong>{' '}
        and hence, follows an approved set of practices that define the best
        work in configuring AWS services.
      </>,
      <>
        <strong>Devoted subject matter expert</strong> to walk you through your
        project and assist teams during the problems.
      </>,
      <>
        <strong>A well-structured team</strong> of technical officers whose
        significant tenure belongs to cloud technologies and AWS services.
      </>,
      <>
        <strong>A 24/7 constant communication</strong> to help you get through
        the AWS assistance, zero instances of delayed service.
      </>,
      <>
        <strong>Simform’s standard auditing and quality assurance</strong> over
        every module of code we deliver through our consulting services.
      </>,
    ],
  },
  featureResourceProps: {
    heading: 'AWS insights from our experts',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/why-work-with-aws-premier-partner/',
        featurecardTitle:
          'Working with an AWS Premier Tier Services Partner: What’s in it for You?',
      },
      {
        blogLink: 'https://www.simform.com/blog/aws-resilience-competency/',
        featurecardTitle: 'Simform Achieves AWS Resilience Competency',
      },
      {
        blogLink: 'https://www.simform.com/blog/aws-control-tower/',
        featurecardTitle:
          'Simform Achieves Service Delivery Partnership for AWS Control Tower',
      },
    ],
  },
}
